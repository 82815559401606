@import "../../styles/contants.scss";

.wrapper {
  @media (min-width: $xl) {
    padding: 3rem;
  }
  @media (max-width: $lg) {
    padding: 2rem;
  }
  @media (max-width: $md) {
    padding: 1rem 1rem;
  }
  @media (max-width: $sm) {
    padding: 1rem;
    background-position: right;
  }
  @media (max-width: $xs) {
    padding: 6rem 1rem 0rem 1rem;
    font-size: 1.55rem;
    text-align: center;
  }
  text-align: center;
  height: fit-content;
  padding: 1.5rem;
  background-image: url("UFMBCpic3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  text-shadow: 1px 2px 2px black;
  font-size: 3rem;
  font-family: "Hepta Slab", serif;
  font-weight: 700;

  .container {
    @media (max-width: $xl) {
      padding: 0;
      font-size: 4rem;
      padding-top: 22rem;
      padding-bottom: 2rem;
      text-align: center;
    }
    @media (max-width: $lg) {
      padding: 0;
      text-align: center;
      padding-top: 13rem;
      font-size: 3rem;
    }
    @media (max-width: $md) {
      font-size: 3rem;
      padding: 0;
      padding-top: 10rem;
      font-size: 2.25rem;
      text-align: center;
      padding-bottom: 1rem;
    }

    @media (max-width: $sm) {
      padding: 0;
      padding-top: 8rem;
      font-size: 1.7rem;
      text-align: center;
      padding-bottom: 2rem;
    }
    @media (max-width: $xs) {
      font-size: 1.55rem;
      padding: 0;
      padding-top: 8rem;
      padding-bottom: 2rem;
    }
    @media (max-width: $xxs) {
      font-size: 1.25rem;
      padding: 0;
      padding-top: 8em;
      padding-bottom: 2rem;
    }
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 4rem;

    .homeText2 {
      text-transform: uppercase;
    }
  }
}

.modal_container {
  background-color: rgba(128, 128, 128, 0.244);
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  .box {
    height: 85%;
    width: 75%;
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-sizing: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .xButton {
      position: relative;
      text-align: right;
      background-color: white;
      text-decoration: none;
      border: none;
      font-weight: 800;
      margin-left: 30rem;
    }
    .modal_body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .modal_heading {
        // @media (min-width: $xl) {
        //   font-size: 5.5rem;
        // }
        // @media (max-width: $xl) {
        //   font-size: 4.5rem;
        // }
        // @media (max-width: $lg) {
        //   letter-spacing: 1px;
        // }
        // @media (max-width: $md) {
        //   font-size: 3.5rem;
        //   margin-bottom: 0.25rem;
        // }
        // @media (max-width: $sm) {
        //   font-size: 2.2rem;
        //   margin-bottom: 0.25rem;
        // }
        // @media (max-width: $xs) {
        //   font-size: 2.5rem;
        //   margin-bottom: 0.25rem;
        // }
        // @media (max-width: $xxs) {
        //   font-size: 2rem;
        // }
        margin: 0;
        color: #781539;
        font-size: 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: 200;
        span {
          display: inline-block;
          font-family: "Cookie", cursive;
          font-weight: 200;
        }
      }
      .modal_pic {
        width: auto;
        height: 65%;
      }
    }

    .continueButton {
      background-color: #781539;
      border: none;
      padding: 0.5rem 1rem;
      color: white;
      font-weight: 600;
      margin: 1rem 0rem;
    }
  }
}
