@import "../../styles/contants.scss";

.wrapper {
  height: auto;
  .container {
    .title {
      @media (max-width: $lg) {
        text-align: center;
        padding-left: 0rem;
      }

      @media (max-width: $md) {
        text-align: center;
        padding-left: 0rem;
      }

      padding-top: 90px;
      padding-bottom: 1px;
      padding-left: 3rem;
      background-color: #781539;
      color: white;
      font-size: 2rem;
      letter-spacing: 1px;
    }

    .details {
      @media (max-width: $lg) {
        gap: 1rem;
        justify-content: space-between;
      }
      @media (max-width: $md) {
        flex-direction: column;
        align-items: center;
      }
      margin: 2rem 3rem;
      display: flex;
      flex-direction: row;
      gap: 3rem;
      align-items: center;

      .detailsDescrp {
        @media (max-width: $lg) {
          width: 50%;
          gap: 1rem;
        }
        @media (max-width: $md) {
          width: 100%;
        }
        width: 65%;

        .detailsDescrpTitle {
          letter-spacing: 1px;
        }

        .detailsDescrpInfo {
          @media (max-width: $lg) {
            font-size: 1rem;
          }
          @media (max-width: $md) {
            font-size: 1.25rem;
          }
          letter-spacing: 1px;
          font-weight: 400;
          line-height: 40px;
        }
      }
      .detailsCard {
        @media (max-width: $lg) {
          width: fit-content;
          padding: 1.5rem;
        }
        @media (max-width: $md) {
          width: fit-content;
          padding: 1.5rem;
        }
        width: 35%;
        height: fit-content;
        background-color: white;
        padding: 2rem;
        border-radius: 20px;
        box-shadow: 0 6px 50px rgb(194 197 204 / 40%);

        .detailsCardCal {
          @media (max-width: $lg) {
            gap: 0.5rem;
          }
          @media (max-width: $md) {
            gap: 0.5rem;
          }
          display: flex;
          flex-direction: row;
          gap: 2rem;
          padding-bottom: 1.5rem;

          .detailsCardCalIcon {
            margin-top: 5px;
          }

          .detailsCardDay {
            @media (max-width: $lg) {
              font-weight: 200;
            }
            font-weight: 200;
            margin: 0;
            letter-spacing: 1px;
          }
        }
        .detailsCardTime {
          @media (max-width: $lg) {
            gap: 0.5rem;
            padding-bottom: 0.5rem;
          }
          display: flex;
          flex-direction: row;
          gap: 2rem;
          padding-bottom: 1.5rem;

          .detailsCardTimeIcon {
            margin-top: 5px;
          }

          .detailsCardTimes {
            margin: 0;
            letter-spacing: 1px;
            font-size: 2rem;
            font-weight: 200;
          }
        }
        .button {
          @media (max-width: $lg) {
            display: flex;
            text-align: center;
            align-items: center;
            align-content: center;
            flex-direction: column;
          }
          display: flex;
          text-align: center;
          align-items: center;
          align-content: center;
          flex-direction: column;
          gap: 2rem;

          .calendarBtn {
            text-align: center;
            width: 10rem;
            font-size: 1.25rem;
            border: none;
            padding: 1rem;
            color: #727577;
            font-weight: 600;
            background: #f7f7f7;
            cursor: pointer;
          }
          .shareBtn {
            width: 10rem;
            font-size: 1.25rem;
            border: none;
            padding: 1rem;
            color: white;
            font-weight: 600;
            background: #781539;
            cursor: pointer;
          }
        }
      }
    }
  }
}
