@import "../../styles/contants.scss";

.wrapper {
  color: white;

  .container {
    @media (max-width: $xl) {
      padding-bottom: 0;
    }
    padding-bottom: 3rem;
    display: flex;
    align-items: flex-start;
    // gap: 3rem;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;

    .banner {
      @media (max-width: $xl) {
        height: 70vh;
      }
      @media (max-width: $lg) {
        height: 55vh;
      }

      background-image: url("praise.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      height: 85vh;

      .bannerTitle {
        @media (max-width: $xl) {
          font-size: 2rem;
          margin: 4rem 3rem;
        }
        @media (max-width: $lg) {
          font-size: 1.5rem;
          margin: 3rem 3rem;
        }
        @media (max-width: $md) {
          font-size: 2rem;
          margin: 2.5rem 1rem;
        }

        font-size: 2rem;
        font-weight: 800;
        margin: 5rem 3rem;
        // margin-bottom: 3rem;
      }
      .bannerVerse {
        @media (max-width: $xl) {
          font-size: 3rem;
          width: 85%;
        }
        @media (max-width: $lg) {
          font-size: 2rem;
          width: 75%;
        }
        @media (max-width: $md) {
          margin: 0 1rem;
          margin-bottom: 1rem;
          font-size: 2rem;
        }
        @media (max-width: $xxs) {
          margin: 0 1rem;
          margin-bottom: 1rem;
          font-size: 1.5rem;
        }
        font-size: 4rem;
        font-family: "Hepta Slab", serif;
        font-weight: 700;
        margin: 0 3rem;
        width: 60%;
      }
      .bannerAuthor {
        @media (max-width: $md) {
          margin: 0 1rem;
          font-size: 1rem;
        }
        @media (max-width: $lg) {
          font-size: 1.25rem;
        }

        font-size: 2rem;
        font-family: "Hepta Slab", serif;
        font-weight: 400;
        margin: 1rem 3rem;
      }
    }
    .desc {
      @media (max-width: $xl) {
        margin: 4rem 3rem;
        font-size: 2rem;
        transform: none;
        opacity: 1;
      }
      @media (max-width: $lg) {
        margin: 3rem 3rem;
        font-size: 1.75rem;
        transform: none;
        opacity: 1;
      }
      @media (max-width: $md) {
        margin: 3rem 3rem;
        font-size: 1.5rem;
        transform: none;
        opacity: 1;
      }
      @media (max-width: $xsm) {
        font-size: 1.25rem;
      }

      margin: 0 3rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      letter-spacing: 1.5px;
      color: #781539;
      margin: 5rem 15rem;
      text-align: center;
      font-weight: 300;
    }
    .ministry {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;
      margin-bottom: 5rem;

      .info {
        @media (max-width: $xl) {
          width: 28%;
        }
        @media (max-width: $lg) {
          width: 45%;
        }
        @media (max-width: $md) {
          width: 80%;
        }

        width: 31%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .minImg {
          border-radius: 25px 25px 0 0;
          height: auto;

          .minImgStyle {
            width: 100%;
            border-radius: 25px 25px 0 0;
          }
        }

        .minInfo {
          @media (max-width: $md) {
            align-items: center;
          }
          background-color: #f7f7f7;
          border-radius: 0 0 25px 25px;
          padding: 1rem;
          padding-bottom: 2rem;
          height: auto;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: flex-start;
        }

        .minInfoTitle {
          @media (max-width: $lg) {
            margin-bottom: 0;
          }

          @media (max-width: $md) {
            width: 100%;
            text-align: center;
          }
          @media (max-width: $xsm) {
            font-size: 1.25rem;
            text-align: center;
          }
          font-size: 1.75rem;
          font-weight: 800;
          margin-bottom: 1rem;
          color: #781539;
        }
        .minInfoDesc {
          @media (max-width: $xl) {
            font-size: 1.25rem;
          }
          @media (max-width: $lg) {
            font-size: 1.5rem;
          }
          @media (max-width: $md) {
            text-align: center;
          }
          @media (max-width: $xsm) {
            font-size: 1rem;
          }
          font-size: 1.5rem;
          line-height: 1.5;
          color: #727577;
          font-weight: 400;
          letter-spacing: 0.25px;
        }

        button {
          @media (max-width: $xsm) {
            font-size: 1rem;
          }
          color: white;
          font-size: 1.25rem;
          font-weight: 800;
          background-color: #9e752f;
          padding: 7px 16px;
          line-height: 1.5;
          cursor: pointer;
          border: none;
          margin-top: 1.25rem;
        }
      }
    }
  }
}
