@import "../../styles/contants.scss";

.wrapper {
  padding: 0rem 1.5rem;

  .contactTitle {
    margin-bottom: 2rem;
    font-size: 3rem;
    letter-spacing: 1.5px;
    color: #781539;
    text-align: center;
    font-weight: 800;
  }

  .container {
    @media (max-width: $xl) {
      gap: 2rem;
      margin-bottom: 2rem;
      justify-content: space-between;
    }
    @media (max-width: $md) {
      gap: 3rem;
      margin-bottom: 2rem;
    }
    padding: 1.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: left;
    gap: 8rem;
    margin-bottom: 3rem;

    .contact {
      @media (max-width: $lg) {
        width: 47%;
      }
      @media (max-width: $md) {
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 45%;

      .form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .topLine {
          @media (max-width: $md) {
            flex-direction: column;
            gap: 2rem;
          }
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .name {
            @media (max-width: $md) {
              width: auto;
            }
            width: 45%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            input {
              @media (max-width: $md) {
                width: auto;
              }
              width: 100%;
              background-color: #f7f7f7;
              font-size: 1rem;
              font-weight: 500;
              color: #727577;
              border: none;
              padding: 1rem;
            }
          }
          .phone {
            @media (max-width: $md) {
              width: auto;
            }
            width: 45%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            input {
              @media (max-width: $md) {
                width: auto;
              }
              width: 100%;
              background-color: #f7f7f7;
              font-size: 1rem;
              font-weight: 500;
              color: #727577;
              border: none;
              padding: 1rem;
            }
          }
        }
        .email {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          input {
            @media (max-width: $md) {
              width: auto;
            }
            width: 100%;
            background-color: #f7f7f7;
            font-size: 1rem;
            font-weight: 500;
            color: #727577;
            border: none;
            padding: 1rem;
          }
        }
        .subject {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          select {
            @media (max-width: $md) {
              width: auto;
            }
            width: 100%;
            background-color: #f7f7f7;
            font-size: 1rem;
            color: #727577;
            border: none;
            padding: 1rem;
          }
        }
        .mess {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          input {
            @media (max-width: $md) {
              width: auto;
            }
            width: 100%;
            background-color: #f7f7f7;
            font-size: 1rem;
            font-weight: 500;
            color: #727577;
            border: none;
            padding: 1rem;
            height: 5rem;
            text-decoration: none;
          }
        }
        input {
          width: 100%;
          font-size: 1rem;
          border: none;
          padding: 1rem;
          color: white;
          font-weight: 600;
          background: #781539;
        }
      }
    }
    .map {
      @media (max-width: $xl) {
        width: 45%;
        display: flex;
      }
      @media (max-width: $md) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
