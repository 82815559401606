@import "../../styles/contants.scss";

.wrapper {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: white;

  .container {
    width: 100%;

    .about {
      @media (max-width: $md) {
        font-size: 2rem;
        font-weight: 800;
        margin: 0px 1rem;
      }
      font-size: 2.5rem;
      font-weight: 800;
      margin: 0 3rem;
    }

    .service {
      @media (max-width: $md) {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0rem;
        padding-bottom: 2rem;
      }
      @media (max-width: $sm) {
        padding: 0;
        padding-bottom: 2rem;
      }
      @media (max-width: $xs) {
        padding-top: 0rem;
        padding-bottom: 2rem;
      }
      padding: 2.5rem 0rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .serviceInfo {
        @media (max-width: $md) {
          width: 85%;
        }
        @media (max-width: $xs) {
          width: 100%;
        }
        background-color: white;

        color: #727577;

        .serviceTitle {
          @media (min-width: $xl) {
            font-size: 5.5rem;
          }
          @media (max-width: $xl) {
            font-size: 4.5rem;
          }
          @media (max-width: $lg) {
            letter-spacing: 1px;
          }
          @media (max-width: $md) {
            font-size: 3.5rem;
            margin-bottom: 0.25rem;
          }
          @media (max-width: $sm) {
            font-size: 2.2rem;
            margin-bottom: 0.25rem;
          }
          @media (max-width: $xs) {
            font-size: 2.5rem;
            margin-bottom: 0.25rem;
          }
          @media (max-width: $xxs) {
            font-size: 2rem;
          }
          color: #781539;
          font-family: "Cookie", cursive;
          font-size: 3rem;
          margin-bottom: 1rem;
        }
        .serviceTimes {
          @media (min-width: $xl) {
            font-size: 2rem;
            line-height: 2.75rem;
            letter-spacing: 1px;
            font-weight: 500;
          }
          @media (max-width: $md) {
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: 1px;
            font-weight: 500;
          }
          @media (max-width: $sm) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
          @media (max-width: $xs) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
          text-transform: uppercase;
          font-size: 1.5rem;
          font-weight: 575;
          line-height: 2.25rem;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          text-align: center;
          justify-content: flex-start;
          align-content: center;
        }
      }
    }
    .churchStatements {
      @media (min-width: $xl) {
        gap: 5rem;
      }
      @media (max-width: $xl) {
        gap: 3rem;
      }
      @media (max-width: $lg) {
        gap: 2rem;
      }
      @media (max-width: $md) {
        gap: 2.5rem;
        padding: 0;
      }
      @media (max-width: $md) {
      }
      padding: 1rem 0;
      width: 100%;
      gap: 7rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .purpose {
        @media (max-width: $xl) {
          width: 38%;
        }
        @media (max-width: $md) {
          width: 80%;
        }
        @media (max-width: $sm) {
          width: 100%;
          padding: 1rem;
        }
        @media (max-width: $xs) {
          width: 100%;
          padding: 1rem;
        }

        width: 30%;
        text-align: center;
        background-color: #f7f7f7;
        border-radius: 25px;
        padding: 2rem;

        .purposeTitle {
          @media (max-width: $lg) {
            font-size: 1.75rem;
            margin-bottom: 0.5rem;
          }

          @media (max-width: $md) {
            margin-bottom: 0.5rem;
          }
          @media (max-width: $sm) {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }
          @media (max-width: $xs) {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }
          font-size: 2rem;
          font-weight: 800;
          margin-bottom: 1.5rem;
          color: #781539;
        }

        .purposeInfo {
          @media (max-width: $lg) {
            font-size: 1.25rem;
          }
          @media (max-width: $sm) {
            font-size: 1rem;
          }
          @media (max-width: $xs) {
            font-size: 1rem;
          }
          font-size: 1.5rem;
          text-align: center;
          line-height: 1.5;
          color: #727577;
        }
      }
      .mission {
        @media (max-width: $xl) {
          width: 38%;
        }
        @media (max-width: $md) {
          width: 80%;
        }
        @media (max-width: $sm) {
          width: 100%;
          padding: 1rem;
        }
        @media (max-width: $xs) {
          width: 100%;
          padding: 1rem;
        }
        width: 30%;
        gap: 1rem;
        text-align: center;
        background-color: #f7f7f7;
        border-radius: 25px;
        padding: 2rem;

        .missionTitle {
          @media (max-width: $lg) {
            font-size: 1.75rem;
            margin-bottom: 0.5rem;
          }

          @media (max-width: $md) {
            margin-bottom: 0.5rem;
          }
          @media (max-width: $sm) {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }
          @media (max-width: $xs) {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }
          font-size: 2rem;
          font-weight: 800;
          margin-bottom: 1.5rem;
          color: #781539;
        }

        .missionInfo {
          @media (max-width: $lg) {
            font-size: 1.25rem;
          }
          @media (max-width: $sm) {
            font-size: 1rem;
          }
          @media (max-width: $xs) {
            font-size: 1rem;
          }
          font-size: 1.5rem;
          text-align: center;
          line-height: 1.5;
          color: #727577;
        }
      }
    }
    .resources {
      @media (max-width: $xl) {
        margin: 3rem 1rem;
        font-size: 3rem;
      }
      @media (max-width: $lg) {
        margin: 3rem 1rem;
        font-size: 2.5rem;
      }
      @media (max-width: $md) {
        font-size: 2rem;
        margin: 3rem 2rem 0rem 2rem;
      }
      @media (max-width: $sm) {
        font-size: 1.25rem;
        margin: 0;
        margin-top: 2.5rem;
        padding: 1rem;
      }
      @media (max-width: $xs) {
        font-size: 1.25rem;
        margin: 0;
        margin-top: 2.5rem;
        padding: 1rem;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 4rem;
      color: white;
      gap: 1rem;
      font-size: 4.5rem;
      font-weight: 800;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.086);
      padding: 2.5rem;
      background: linear-gradient(13deg, #510e27, #781539, #78153988);
      border: 1px solid #510e27;

      button {
        @media (max-width: $xl) {
          padding: 10px 25px;
        }
        @media (max-width: $lg) {
          padding: 10px 25px;
        }

        @media (max-width: $md) {
          margin-top: 0rem;
          padding: 5px 20px;
        }
        @media (max-width: $sm) {
          padding: 5px 15px;
          font-size: 1rem;
          margin-top: 0rem;
        }

        color: white;
        font-size: 1.5rem;
        font-weight: 800;
        background-color: #9e752f;
        padding: 15px 32px;
        line-height: 1.5;
        cursor: pointer;
        border: none;
        margin-top: 1rem;
      }
    }
  }
}
