@import "../../styles/contants.scss";

.wrapper {
  margin: 1.5rem;

  .container {
    margin: 0rem 2rem;

    .galleryTop {
      .galleryTitle {
        margin: 2rem 0rem;
        font-size: 3rem;
        color: #781539;
        text-align: center;
        font-weight: 800;
        letter-spacing: 1.5px;
      }

      .tags {
        gap: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin: 2rem auto;
      }
    }
    .gallery {
      margin-top: 3rem;
    }
  }
}
.tag {
  font-size: 1rem;
  border: none;
  padding: 0.5rem 1.75rem;
  margin-right: 1rem;
  background-color: #f7f7f7;
  color: #727577;
  font-weight: 600;
  cursor: pointer;

  &:on {
    background-color: #727577b7;
  }
}
