.navbar-window {
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar {
  background: #fff;
  height: 90px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 999;
  justify-content: space-around;
}

.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 1500px;
  justify-content: space-between;
}

.navbar-logo {
  color: #781539;
  justify-self: start;
  margin-left: 40px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar-logo-img {
  height: 80%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  text-align: center;
  width: 70vw;
  justify-content: flex-end;
  margin-right: 2rem;
  list-style-type: none;
}

.nav-item {
  height: -80px;
  list-style-type: none;
  font-weight: 300;
}

.nav-links {
  color: #781539;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.2rem 1rem;
}

.nav-links:hover {
  border-bottom: 4px solid #781539;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #781539;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

#give {
  font-weight: 800;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    margin: 0;
    padding: 0;
    background: #fff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #781539c7;
    color: #fff;
    border-radius: 0;
    font-weight: 800;
    border: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #781539;
    font-size: 2rem;
  }
}
