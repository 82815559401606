@import "../../styles/contants.scss";

.wrapper {
  @media (max-width: $lg) {
    margin: 0rem 1rem;
  }
  margin: 0rem 3rem;
  color: #781539;
  background: white;
  display: flex;

  .container {
    @media (max-width: $md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      padding-top: 0;
    }
    @media (max-width: $sm) {
      gap: 2rem;
    }
    @media (max-width: $xs) {
      gap: 2rem;
    }
    gap: 1rem;
    padding: 3rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .logo {
      @media (max-width: $lg) {
        width: 13%;
      }
      @media (max-width: $md) {
        width: 35%;
        margin-top: 3rem;
      }
      width: 13%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .newsletter {
      @media (max-width: $xl) {
        width: 50%;
      }
      @media (max-width: $lg) {
        width: 50%;
      }
      @media (max-width: $md) {
        width: 80%;
      }
      @media (max-width: $sm) {
        width: 100%;
      }
      @media (max-width: $xs) {
        width: 100%;
      }
      width: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;

      width: 35%;
      gap: 1rem;

      .newsletterTitle {
        @media (min-width: $xl) {
          font-size: 1.25rem;
        }
        @media (max-width: $md) {
          font-size: 2rem;
        }
        @media (max-width: $sm) {
          font-size: 1.5rem;
        }
        @media (max-width: $xs) {
          font-size: 1.5rem;
        }
        text-align: center;
        font-size: 1rem;
        font-weight: 800;
        letter-spacing: 0.5px;
      }

      .newsletterDesc {
        @media (min-width: $xl) {
          font-size: 1.25rem;
        }
        @media (max-width: $md) {
          font-size: 1.25rem;
        }
        @media (max-width: $sm) {
          font-size: 1rem;
          text-align: center;
        }
        @media (max-width: $xs) {
          font-size: 0.75rem;
        }
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
      }
      form {
        @media (max-width: $sm) {
          justify-content: center;
          display: flex;
        }
        width: 100%;

        .email {
          @media (min-width: $xl) {
            width: 59%;
          }
          @media (max-width: $xl) {
            width: 59%;
          }
          @media (max-width: $lg) {
            width: 50%;
          }
          width: 60%;
          background-color: #f7f7f7;
          font-size: 1rem;
          color: #727577;
          border: none;
          padding: 1rem;
        }
        .subsc {
          @media (max-width: $lg) {
            width: 38%;
          }
          width: 33%;
          font-size: 1rem;
          border: none;
          padding: 1rem;
          color: white;
          font-weight: 600;
          background: #781539;
        }
      }
      .invalidEmail {
        font-weight: 600;
      }
    }

    .contact {
      // @media (max-width: $xl) {
      //   width: 22%;
      // }
      // @media (max-width: $lg) {
      //   width: 34%;
      // }
      // @media (max-width: $md) {
      //   width: 80%;
      // }
      // @media (max-width: $sm) {
      //   width: 100%;
      // }
      // @media (max-width: $xs) {
      //   width: 100%;
      // }
      width: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      gap: 1rem;

      .contactTitle {
        @media (min-width: $xl) {
          font-size: 1.25rem;
        }
        @media (max-width: $md) {
          font-size: 2rem;
        }
        @media (max-width: $sm) {
          font-size: 1.5rem;
        }
        @media (max-width: $xs) {
          font-size: 1.5rem;
        }
        text-align: center;
        font-size: 1rem;
        letter-spacing: 0.5px;
        font-weight: 800;
      }
      .addrAndPhone {
        @media (min-width: $xl) {
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
        }
        @media (max-width: $xl) {
          display: flex;
          gap: 0.5rem;
          flex-direction: column;
        }
        @media (max-width: $md) {
          font-size: 2rem;
          display: flex;
          flex-direction: row;
          gap: 1rem;
          width: 100%;
          text-align: left;
          justify-content: space-evenly;
        }
        @media (max-width: $sm) {
          font-size: 1.5rem;
          gap: 1rem;
          text-align: center;
          flex-direction: column;
        }
        @media (max-width: $xxs) {
          gap: 0.5rem;
        }

        .address {
          @media (min-width: $xl) {
            font-size: 1.25rem;
          }

          @media (max-width: $md) {
            font-size: 1.25rem;
          }
          @media (max-width: $sm) {
            font-size: 1rem;
          }
          @media (max-width: $xs) {
            font-size: 0.75rem;
          }
          font-size: 1rem;
          font-weight: 300;
          letter-spacing: 1px;
        }
        .phone {
          @media (min-width: $xl) {
            font-size: 1.25rem;
          }
          @media (max-width: $md) {
            font-size: 1.25rem;
          }
          @media (max-width: $sm) {
            font-size: 1rem;
          }
          @media (max-width: $xs) {
            font-size: 0.75rem;
          }
          font-size: 1rem;
          font-weight: bold;
          letter-spacing: 1px;
        }
      }
      .fax {
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 1px;
      }

      .icons {
        @media (max-width: $md) {
          text-align: center;
          margin-top: 2rem;
        }
        @media (max-width: $lg) {
          display: flex;
          justify-content: center;
        }
        a {
          margin-right: 1.5rem;
          color: #781539;
        }
      }
    }
  }
}
